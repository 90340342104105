import React, { useEffect } from 'react';

const OpenMall = ({ botId, questionId }) => {
  useEffect(() => {
    // Create the custom algho-viewer element with the dynamic botId
    const tag = document.createElement("algho-viewer");
    tag.setAttribute("bot-id", botId);
    tag.setAttribute("question-id", questionId); //&QuestionId
    tag.setAttribute("question-repeat", "false");
    tag.setAttribute("widget", "true");
    tag.setAttribute("audio", "true");
    tag.setAttribute("voice", "true");
    tag.setAttribute("dhi", "true");
    tag.setAttribute("floating", "true");
    tag.setAttribute("transparent", "true");
    tag.setAttribute("avatar-delayed", "true");
    tag.setAttribute("avatar-delay", "-1");
    tag.setAttribute("open", "true");
    tag.setAttribute("tooltip", "Ciao sono Anna. Come posso aiutarti?");
    tag.setAttribute("z-index", "9999");
    document.body.appendChild(tag);

    // Create and append the script element
    const script = document.createElement("script");
    script.id = "algho-viewer-module";
    script.type = "text/javascript";
    script.defer = true;
    script.charset = "UTF-8";
    script.src = "https://virtualassistant.openmall.it/algho-viewer.min.js";
    document.body.appendChild(script);

    // Cleanup function to remove the elements when the component unmounts
    return () => {
      if (document.body.contains(tag)) {
        document.body.removeChild(tag);
      }
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, [botId]); // re-run if botId changes

  // This component doesn't render any visible UI elements
  return null;
};

export default OpenMall;
