// CreateProject.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CreateProjectContainer, Header, Title, CardsContainer, ProjectCard } from '../styles/CreateProjectStyles';
import ProjectConfiguration from './creation_steps_components/ProjectConfiguration';
import CreationConfirmation from './creation_steps_components/CreationConfirmation';
import UpgradePlan from './UpgradePlan';
import CheckUpgrade from './creation_steps_components/CheckUpgrade';
import ChooseTemplate from './ChooseTemplate'; // Import the new component

const StepEnum = {
  SELECT_PROJECT_TYPE: 'select_type',
  CHOOSE_TEMPLATE: 'choose_template', // New step for choosing a template
  PROJECT_CONFIGURATION: 'configure',
  CONFIRMATION_PAGE: 'confirmation',
  UPGRADE_PLAN_PAGE: 'upgrade_plan',
  CHECK_PAYMENT: 'check_payment',
};

const CreateProject = () => {
  const { step } = useParams();
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState({
    projectType: '',
    propertyName: '',
    imageCover: null,
    propertyValue: '',
    isFurnished: false,
    propertyPlan: null,
    configurations: 1,
    streamingMinutes: 15,
  });

  useEffect(() => {
    if (!step) {
      navigate(`/create_project/${StepEnum.SELECT_PROJECT_TYPE}`, { replace: true });
    }
  }, [step, navigate]);

  const handleProjectTypeSelection = (type) => {
    setProjectData({ ...projectData, projectType: type });
    if (type === 'Template') {
      navigate(`/create_project/${StepEnum.CHOOSE_TEMPLATE}`); // Navigate to choose template step
    } else {
      navigate(`/create_project/${StepEnum.PROJECT_CONFIGURATION}`);
    }
  };

  const handleNextStep = (nextStep) => {
    navigate(`/create_project/${nextStep}`);
  };

  const renderStepContent = () => {
    switch (step) {
      case StepEnum.SELECT_PROJECT_TYPE:
        return (
          <CardsContainer>
            <ProjectCard onClick={() => handleProjectTypeSelection('Template')}>
              <img src={`${process.env.PUBLIC_URL}/template_create_image.jpg`} alt="Template" />
              <h2>Template</h2>
              <p>Start applying immediately our AI model on a ready-made house with or without furniture inside. <br></br><br></br>Enjoy creating new configurations on a set of different rooms</p>
            </ProjectCard>
            <ProjectCard 
              onClick={() => handleProjectTypeSelection('Upload House Plan')}
              /*style={{ pointerEvents: 'none', opacity: 0.5 }} // Disable click and grey out the card */
            >
              <img src={`${process.env.PUBLIC_URL}/house_plan_create_image.jpg`} alt="Upload House Plan" />
              <h2>Upload House Plan</h2>
              <p>Create a project starting from a house plan in .dwg or .pdf format. <br></br><br></br>After project submission it could take up to 48 hours for the virtual house to be ready</p>
            </ProjectCard>
          </CardsContainer>
        );
      case StepEnum.CHOOSE_TEMPLATE:
        return <ChooseTemplate />; // Render the ChooseTemplate component
      case StepEnum.PROJECT_CONFIGURATION:
        return (
          <ProjectConfiguration 
            projectData={projectData} 
            setProjectData={setProjectData} 
            nextStep={handleNextStep} 
            StepEnum={StepEnum}
          />
        );
      case StepEnum.CONFIRMATION_PAGE:
        return <CreationConfirmation />;
      case StepEnum.UPGRADE_PLAN_PAGE:
        return <UpgradePlan />;
      case StepEnum.CHECK_PAYMENT:
        return <CheckUpgrade nextStep={handleNextStep} StepEnum={StepEnum} />;
      default:
        return null;
    }
  };

  return (
    <CreateProjectContainer>
      <Header>
        <Title>Create Project</Title>
      </Header>
      {renderStepContent()}
    </CreateProjectContainer>
  );
};

export default CreateProject;
